<template>
  <div>
    <div
      v-if="!voucherResult.display"
      class="w-full flex space-x-2 items-center"
      >
      <vs-input
        class="flex-1"
        v-model="voucherInput"
        placeholder = " Discount code"
        @keyup.enter="getVoucher"
      />
      <vs-button
        color="primary"
        type="filled"
        class="h-full border border-solid border-tag-success"
        @click="getVoucher"
        :disabled="voucherInput.length === 0"
      >
        Apply
      </vs-button>
    </div>
    <vs-alert v-if=" voucherResult.display && voucherResult.status ==='success' " active="true" color="success">
      <div class="justify-between flex items-center">
        <div class="flex items-center gap-2">
          <feather-icon icon='TagIcon' svgClasses='h-4 w-4' />{{ `${voucher.voucher_code} has been applied` }}
        </div>
        <feather-icon @click="closeInvalid" icon='XIcon' svgClasses='h-4 w-4' class='ml-1 cursor-pointer' />
      </div>
    </vs-alert>
    <vs-alert v-if=" voucherResult.display && voucherResult.status ==='warning' " active="true" color="warning">
      <div class="justify-between flex items-center">
        {{ voucherResult.message }} <feather-icon @click="closeInvalid" icon='XIcon' svgClasses='h-4 w-4' class='ml-1 cursor-pointer' />
      </div>
    </vs-alert>
    <vs-alert v-if=" voucherResult.display && voucherResult.status ==='danger' " active="true" color="danger">
      <div class="justify-between flex items-center">
        {{ voucherResult.message }} <feather-icon @click="closeInvalid" icon='XIcon' svgClasses='h-4 w-4' class='ml-1 cursor-pointer' />
      </div>
    </vs-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default{
  name: "CheckoutVoucher",
  data(){
    return {
      voucherResult:{
        title: '',
        message: '',
        status: 'warning', // success| warning
        details: '',
        display: false
      },
      voucher: this.voucherData,
      voucherInput:'',
      totalAmount: this.totalBalance,
    }
  },
  props: ["voucherData", "totalBalance", "totalQty", "retoolDiscounts", "shippingDiscounts"],
  methods:{
    ... mapActions('voucher', [
      "getClinicVoucherByCode"
    ]),
    closeInvalid(){
      this.voucherInput='';
      this.voucher={};
      this.voucherResult.title='';
      this.voucherResult.message='';
      this.voucherResult.status='',
      this.voucherResult.details='',
      this.voucherResult.display=false;
      this.$emit("updateVoucher", null);
    },
    isVoucherAlpicableToProduct(voucher) {
      const productStoreId = this.$store.state.eCommerce.cartItems.map(cartProduct => cartProduct.productStoreId)
      if(!voucher.products.length){
        return {
          isAvailable: true,
          message: ""
        }
      }

      const voucherProductId = voucher.products.map(voucherProduct => voucherProduct.productId)
      const duplicates = []
      productStoreId.forEach(cartItem => {
        if (voucherProductId.includes(cartItem) && !duplicates.includes(cartItem)) {
            duplicates.push(cartItem);
        }
      });
      if(duplicates.length > 0){
        return {
          isAvailable: true,
          message: ""
        }
      }
      return {
        isAvailable: false,
        message: "Voucher is not applicable to product/s!"
      }

    },
    otherDiscountEnabled(voucher) {
      const isOtherDiscountEnabled = voucher.combination.includes("other-discount");
      if (!this.retoolDiscounts.length || isOtherDiscountEnabled) {
        return {
          isAvailable: true,
          message: ""
        }
      }

      if (this.retoolDiscounts.length) {
        return {
          isAvailable: false,
          message: "Voucher cannot be applied with other discounts."
        }
      }
    },
    shippingDiscountEnabled(voucher) {
      const isShippingDiscountEnabled = voucher.combination.includes("shipping-discount");
      if (!this.shippingDiscounts.length || isShippingDiscountEnabled) {
        return {
          isAvailable: true,
          message: ""
        }
      }

      const isDiscounted = this.shippingDiscounts.some((disc) => disc.amount < 0);
      if (isDiscounted) {
        return {
          isAvailable: false,
          message: "Voucher cannot be applied with shipping discounts."
        }
      } else {
        return {
          isAvailable: true,
          message: ""
        }
      }
    },
    handleVoucherRestrictions(data) {
      /** 
      * check if rules apply
      * show result
      * if applicable aplly voucher to checkout
      */
      const isValid = this.isVoucherAlpicableToProduct(data);
      const isApplicableWithOtherDiscount = this.otherDiscountEnabled(data);
      const isApplicableWithShippingDiscount = this.shippingDiscountEnabled(data);
      if(!isValid.isAvailable || !isApplicableWithOtherDiscount.isAvailable || !isApplicableWithShippingDiscount.isAvailable){
        this.voucher = {};
        this.voucherResult.title = data.discount_title;
        this.voucherResult.message = isValid.message || isApplicableWithOtherDiscount.message || isApplicableWithShippingDiscount.message;
        this.voucherResult.status = 'warning';
        this.voucherResult.display = true;
        this.$emit("updateVoucher", null);
        this.$vs.loading.close();
        return
      }

      this.voucher = data;
      this.voucherResult.title = data.discount_title;
      this.voucherResult.message = '';
      this.voucherResult.status = 'success';
      this.voucherResult.display = true;
      this.$emit("updateVoucher", data);
      this.$vs.loading.close();
    },
    async getVoucher(){
      /**
       * fetch clinic voucher by code
       */
      this.$vs.loading();
      try {
        const res  = await this.getClinicVoucherByCode({
          voucher_code :this.voucherInput,
          clinicId: this.$store.state.clinicId,
          totalBalance: this.totalBalance || 0,
          totalQty: this.totalQty || 0,
        })
        if(res.status === 200){
          this.handleVoucherRestrictions(res.data.data[0])
        }
      } catch (error) {
        if(error.data){
          this.voucher  = {};
          this.voucherResult.title = '';
          this.voucherResult.message = error.data.message;
          this.voucherResult.status = 'danger';
          this.voucherResult.display = true;
          this.$emit("updateVoucher", null);
          this.$vs.loading.close();
        }
      }
    },
  },
  watch: {
    voucher: {
      handler(newVal) {
        if(Object.keys(newVal).length) {
          if (!this.voucherInput) {
            this.voucherInput = newVal.voucher_code;
          }
          this.handleVoucherRestrictions(newVal)
        }
      },
      immediate: true,
      deep: true,
    },
    "$store.state.clinicId": {
      handler(val) {
        if (val && this.voucherInput) {
          this.getVoucher();
        }
      },
    },
    totalQty: {
      handler(val){
        if (val && this.voucherInput) {
          this.getVoucher();
        }
      },
    }
  },
}
</script>
